import axios from "../utils/requests";

const frontUrl = window.location.hostname;

let BASE_URL = "";
switch (frontUrl) {
  case 'localhost':
    // BASE_URL = "http://localhost:3333";
    BASE_URL = "http://localhost:8000";
    break;
  case 'bezla-rates.vercel.app':
    BASE_URL = "https://bezla-backend-homolog.axion.company";
    break;
  case 'rate.bezla.com':
    BASE_URL = "https://rates-backend.bezla.co";
    break;
  default:
    BASE_URL = "https://rates-backend.bezla.co";
}

const addDays = (base, days) => {
  const date = new Date(base).getTime() + 1000 * 60 * 60 * 24 * days;
  return new Date(date).toISOString().split("T")[0];
};

// 1. make dates array from start to end date;
const buildParams = (hotel, startDate, endDate) => {
  let currentDate = startDate.split("T")[0];
  let arrDates = [];
  do {
    const checkIn = currentDate;
    const checkOut = addDays(checkIn, 1);
    currentDate = checkOut;
    arrDates.push({
      checkIn,
      checkOut,
    });
  } while (new Date(currentDate).getTime() <= new Date(endDate).getTime());
  return { 
    hotelId: hotel.hotelId,
    hotelName: hotel.hotelName,
    hotelAddress: hotel.hotelAddress,
    dateRange: startDate.split("T")[0] + " - " + endDate.split("T")[0],
    dates: arrDates, 
  };
};

const getEvents = async (hotel, startDate, endDate) => {
  console.log("hotel infos >> ", hotel);
  console.log("startDate >> ", startDate);
  console.log("endDate >> ", endDate);
  const params = buildParams(hotel, startDate, endDate);
  try {
    const { data } = await axios.post(`${BASE_URL}/features/rates/eventsNearby`,params);
    return data || [];
  } catch (err) {
    console.log(err);
    return [];
  }
};

export default getEvents;